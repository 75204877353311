import{
    Session,
    Conference,
    ParticipantsGrid,
    ScreenSharingPresentationBox,
    ScreenSharingActionBar,
    useScreenSharing,
    Space,
    useRecording,
    useAudio,
    useVideo,
    useSession,
    useConference,
    useParticipants,
    RecordingStatus,
    ShareStatus,
    RecordingActionBar,
  } from '@dolbyio/comms-uikit-react';
  import VoxeetSDK from '@voxeet/voxeet-web-sdk';
  import Dropdown from "react-bootstrap/Dropdown";
  import Spinner from 'react-bootstrap/Spinner';
  import React, { useEffect, useState,useCallback, useRef} from 'react';
  import {
    RecordingActionBarTexts,
    fallbackButtonText,
    fallbackText,
    screenSharingActionBarTexts,
    buttonContainerStyle,
    maximizedSharedScreen,
    minmizedSharedScreen,
    btnMax,
    btnMax2,
    btnMax3,
    btnMax4,
    maximizedlayout,
    maximizedlayout2,
    maxlayoutstyle,
    minlayoutstyle,
    localTextInGrid,
  } from '../../utils/constant';
  import EnabledAudioIcon from './icons/enabledAudioIcon';
  import DisabledAudioIcon from './icons/disabledAudioIcon';
  import EnabledVideoIcon from './icons/enabledVideoIcon';
  import DisabledVideoIcon from './icons/disabledVideoIcon';
  import { Tooltip as ReactTooltip } from "react-tooltip";
  import Essenitals from './participantsView';
  import { useUnityContext } from "react-unity-webgl";
  import {UNITY_URLS} from '../../utils/config';
  import axios from 'axios';


const Content = (props) => {

    const modalRef = useRef(null);
   
    const {addEventListener, removeEventListener} = useUnityContext(UNITY_URLS);
    const [isVideo2, setVideo2] = useState(false);
    const [role, setrole] = useState('');
    const [isAudio2, setAudio2] = useState('');
    const [conferenceId, setConferenceId] = useState();
    const [isMaxScreen, setMaxScreen] = useState(true);
    const [isList, setShowList] = useState(false);
    const { participant } = useSession();
    const { conference, leaveConference } = useConference();
    const { participants ,participantsStatus} = useParticipants();
    const { p1 ,setp1} = useState();
    var { isSpeaking } = {}
    const [langsused, setlangsused] = useState({id:false});
    const [auidoOpt, setAudioOpt] = useState({id:false});
    const [webCamOpt, setWebCamOpt] = useState({id:false});
    const [shareScreenOpt, setShareScreenOpt] = useState({id:false});
    const [intervalId, setIntervalId] = useState(null);
    const { closeSession } = useSession();
    const { toggleAudio,isAudio } = useAudio(false);
    const { toggleVideo , isVideo} = useVideo();
    const {firstPresenter, owners, status, isLocalUserPresentationOwner, isPresentationModeActive,startScreenShare,stopScreenShare } = useScreenSharing();
    const { status: recordingStatus, isLocalUserRecordingOwner } = useRecording();
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [CloseSessionModal, setCloseSession] = useState(false)
    const [counter, setCounter] = useState(5);


    //Interpreter Features
    const [isSpeakerOn, setSpeakers] = useState(false);
    const [recordSpeakers, setRecordSpeakers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [speakerPerson, setSpeakerPerson] = useState(true)
    const [interpreterPerson, setInterpreterPerson] = useState(false)
    const [speakerID, setSpeakerID] = useState('')
    const [interpreterID, setinterpreterID] = useState('')
    const [spinnerLoad1,setSpinner1] = useState(false)
    const [spinnerLoad2,setSpinner2] = useState(false)
    const [connected, setConnected] = useState(false);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [shouldFetch, setShouldFetch] = useState(true);



   const isPresentationActive =
   status === ShareStatus.Active || (isLocalUserPresentationOwner && isPresentationModeActive);
   const setInsert = useCallback(async (username,mark,userID) =>{
            
      if(mark === 'interpreter' || mark === 'speaker' ){
      //console.log("Inserting...")
      axios.post('https://adminctrl.holofair.io/api/insert/', {pn:username,uid:userID,audio:'',video:'',sharescreen:'',role:mark,is_active:1})
      .then((response) => {
        console.log('Response:', response.data);
        setSpinner2(false)
        if(mark === 'speaker'){
          console.log(speakerID)
          localStorage.setItem("markSpeaker","yes")
          setSpinner1(false)
        }
  
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    }
    else{
      console.log("no need for insertion")
    }

    },[speakerID])
  


  const setleaveSpeakers = useCallback(async (val) =>{
    console.log('Updating....',val)
     axios.put('https://adminctrl.holofair.io/api/leaveSession', {is_active:0,role:val})

    .then((response) => {
      console.log('Response:', response.data);
      if(val === 'speaker') {setSpeakerID(''); setSpinner1(false)}
      if(val === 'interpreter') { setinterpreterID(''); setSpinner2(false)}
      // setShow()
    })
    .catch((error) => {
      console.error('Error:', error);
    });

   },[])


   const checkSpeaker =  useCallback (async (id) => {
    //Note True - not to listen to 
    axios.get('https://adminctrl.holofair.io/api/getSpeaker?role=speaker')
      .then((response) => {
         
        // console.log('Response:', response.data.message[0].uid);
       // console.log(response)
        if(response.data.message.length > 0){
          if(response.data.message[0].role === 'speaker' && response.data.message[0].is_active === 1 && id === response.data.message[0].uid ){
              console.log("Speaker left")
              setleaveSpeakers('speaker')
           

      }
    }
        
      })
      .catch((error) => { 
        console.error('Error:', error);
      });

    },[setleaveSpeakers])



  const endCall =  useCallback( async () => {
     if(status === 'active' && isPresentationModeActive){
       alert("Pleae stop sharing before ending the call")
     }else{
       localStorage.setItem('callstatus','')
       console.log(`Leaving the conference`)
     try {

      if(props.role === 'interpreter'){
        setleaveSpeakers('interpreter')
      }
      if(participant?.id === interpreterID){
        setleaveSpeakers('interpreter')
      }
 
      await checkSpeaker(participant?.id)
      await VoxeetSDK.conference.leave();
      await VoxeetSDK.session.close();
      console.log('Successfully left the session');
    } catch (error) {
      console.error('Error leaving the session:', error);
    }
       props.update(false)
       props.clearError();
       
     }
   },[checkSpeaker, interpreterID, isPresentationModeActive, participant?.id, props, setleaveSpeakers, status]);







   const micUnmic = useCallback(() => {
    console.log('mic')
    if(isAudio){
      VoxeetSDK.audio.local.start()
    }else{
      VoxeetSDK.audio.local.stop()
    }

    toggleAudio()
  },[isAudio, toggleAudio])

   
 


   const leaveDolbyfunc = useCallback(()=>{
    console.log("Leaving now")
   
     endCall();

    
     if(!isAudio)
       micUnmic()

  },[endCall, isAudio, micUnmic])


  const getAllSpeakers =  useCallback (async () => {
    setRecordSpeakers([])
    //Note True - not to listen to 
    setIsLoading(true); // Start loading
    try {
      await axios.get('https://adminctrl.holofair.io/api/getAllSpeakers')
      .then((response) => {
        if(response.data.message.length > 0){
          setRecordSpeakers(response.data.message)

        }
        else{
          setRecordSpeakers([])
        }
        }).catch((error) => { 
          console.error('Error:', error);
        });


     
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false); // Finish loading regardless of the outcome
    }
    
  
        
    },[])


  async function showSpeakers(){
    setSpeakers(!isSpeakerOn)
   // await sleep(500)
     getAllSpeakers()
    
  }



  const markInterpreter = async (username,id,mark) => {
    console.log(id)
    console.log(interpreterID)
    setSpinner2(true)
    
    
    //if(speakerID !== '' && id === interpreterID && interpreterID !== ''){
    if(speakerID !== '' && id === speakerID){
      setSpinner2(false)
     return alert('You cannot mark two roles at the same time. Please unmark the current role , then try again')
    }
    if(id !== interpreterID && interpreterID !== "" ){
      setSpinner2(false)
      return alert("Interpreter has been already assigned. Please unmark the current interpreter and try again")
    }
   
      setAudioOpt(prevValues => ({
        ...prevValues,
        [id]: !prevValues[id] 
      }));
   
     
      if(mark === 'interpreter' && interpreterID === ''){
        setinterpreterID(id);
        setInsert(username,mark,id); 
   
      }
      else if(mark === 'interpreter' && id === interpreterID && interpreterID !== '') {
        setSpinner2(false)
        setinterpreterID('');
        setleaveSpeakers(mark);
      }
    
  }

  const markSpeaker = async (username,id,mark) => {
    console.log(id) 

    setSpinner1(true)

   // if(interpreterID !== '' && id === speakerID && speakerID !== ''){
    if(interpreterID !== '' && id === interpreterID){
      setSpinner1(false)
      return alert('You cannot mark two roles at the same time. Please unmark the current role , then try again')
     }
     if(id !== speakerID && speakerID !== "" ){
       setSpinner1(false)
       return alert("Speaker has been already assigned. Please unmark the current speaker and try again")
     }
    setWebCamOpt(prevValues => ({
      ...prevValues,
      [id]: !prevValues[id] 
    }));

    if(mark === 'speaker' && speakerID === ''){
      setSpeakerID(id);
      setInsert(username,mark,id)
    }
    else if(mark === 'speaker' && id === speakerID && speakerID !== '') {setSpeakerID('');setleaveSpeakers(mark)}
  } 



  const getInterpreter =  useCallback (async (id) => {
    //Note True - not to listen to 
    axios.get('https://adminctrl.holofair.io/api/getInterpreter?role=interpreter')
      .then((response) => {
         
        // console.log('Response:', response.data.message[0].uid);
        response.data.message.map((record,index) => {
          
        if(response.data.message.length > 0 && record.role === 'interpreter' && record.is_active === 1 ){
            setinterpreterID(record.uid)
            console.log('Yes, has joined')
            console.log(record.uid)
            localStorage.setItem('joinedInp','yes')
            setShouldFetch(false);
            // setMessage('interpreter')
          if(participant?.id === record.uid){
            props.onUpdateValue('interpreter')
          }
    
         if((interpreterID && props.role !== 'interpreter' ) || (record.uid && props.role !== 'interpreter')){
          const remoteParticipant = VoxeetSDK.conference.participants.get(interpreterID?interpreterID:record.uid);
          VoxeetSDK.audio.remote.stop(remoteParticipant);
          console.log(remoteParticipant) 
          setShouldFetch(false);
         }

        }
        else{   
          console.log("No Interpreter has joined yet")
        }
      })

      return response.data;
        
      })
      .catch((error) => { 
        console.error('Error:', error);
      });

    },[interpreterID, participant?.id, props])



    
    const handleClickOutside = useCallback((event) => {
      console.log(event.target)
      console.log(modalRef.current)
      
      if (isSpeakerOn && modalRef.current && !modalRef.current.contains(event.target)) {
          setSpeakers(false)
      }
  },[isSpeakerOn]);


  const stopCtrl = () => {
    props.unityCtrl();
  }


  
    useEffect(() => {


      let intervalId = null;
      const startFetching = async () => {
        //setLoading(true);
        try {
          if(shouldFetch === true)
           getInterpreter();
        
        } catch (error) {
          setError(error);
         
        }
      }


      if (shouldFetch) {
       // console.log(shouldFetch)
        startFetching(); // Initial fetch
        intervalId = setInterval(() => {
          startFetching();
        }, 7000); // Set up an interval to fetch data every 10 seconds
      }



       const handler = (participant) => {
        console.log(participant.info.name, 'status:', participant.status);
        console.log(participant.info.name, 'has audio enabled:', participant.audioTransmitting);
       // console.log(participant.id)

        if(participant.status === 'Left' && participant.id === interpreterID){
          setinterpreterID('')
          setleaveSpeakers('interpreter')
         }
         if(participant.status === 'Left' && participant.id === speakerID){
          setSpeakerID('')
          setleaveSpeakers('speaker')
         }
  
    
        if(isPresentationActive){
          //console.log('Yes, 337')
          var videoElement = document.querySelector('.PresentationBox-module_container__muYQW video');
          videoElement.id = (participant)?participant.id:'vid337_presentation';
          props.videoTag('VideoIDListener', 'SetVideoTag',  videoElement.id);
        }
        
      };


      if(localStorage.getItem('ppid') === ''){
        participants.map((p,index) => { 
          return (p.info.name === props.usern) ? localStorage.setItem('ppid',p.id)  : ''
        });
       
      }

     // console.log(props.role)


      // register the handler for 'participantUpdated' event
      VoxeetSDK.conference.on('participantUpdated', handler);
      //document.addEventListener('mousedown', handleClickOutside);
      addEventListener("leaveDolby",leaveDolbyfunc);
      window.addEventListener('beforeunload', leaveDolbyfunc);
     
      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
      //  document.removeEventListener('mousedown', handleClickOutside);
        VoxeetSDK.conference.removeListener('participantUpdated', handler);
       
        //
        removeEventListener("leaveDolby",leaveDolbyfunc);
        window.removeEventListener('beforeunload', leaveDolbyfunc);
      };
    }, [CloseSessionModal, addEventListener, conferenceId, counter, getInterpreter, handleClickOutside, interpreterID, isPresentationActive, leaveDolbyfunc, participants, participantsStatus, props, props.id, props.mname, props.usern, removeEventListener, role, setInsert, setleaveSpeakers, shouldFetch, speakerID]);

    function g(){
      console.log("yes")
      // alert("Yes")
      //setVideo(true)
    }

      const isRecordingActive = isLocalUserRecordingOwner || recordingStatus === RecordingStatus.Active;

      async function startScreenSharing () {
        try {
    
        if(isPresentationModeActive) stopScreenShare()
        if(status === 'active' && !isPresentationModeActive)
        //console.log('Already there');
        alert('Someone is Already Presenting')
        else
        startScreenShare();
        // alert('Already running') 
        } catch (error) {
          console.error('Error accessing screen media stream:', error);
        }
      };

      async function ScreenView() {
        setMaxScreen(!isMaxScreen)
      }

     

      async function showParticipants(){
        setShowList(!isList)
      }

      async function CloseParticipantsList(){
        setShowList(false)
      }

    

     // Controls

    // End Controls


      async function videoUnvideo(){

        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        stream.getTracks().forEach(track => track.stop());

      // setVideo2(!isVideo2)
       if(!isVideo2){
       VoxeetSDK.video.local.start()
       setVideo2(true)
       }
       else{
       VoxeetSDK.video.local.stop()
       // toggleVideo()
       setVideo2(false)
       }
      }


      const getOriginalSpeaker =  useCallback (async (id) => {
        //Note True - not to listen to 
        axios.get('https://adminctrl.holofair.io/api/getSpeaker?role=speaker')
          .then((response) => {
             
            // console.log('Response:', response.data.message[0].uid);
            console.log(response)
            if(response.data.message.length > 0){
              if(response.data.message[0].role === 'speaker' && response.data.message[0].is_active === 1 ){
                console.log("Set Speaker")
                 response.data.message.map((record,index) => {
                

                    //console.log(record.role)
                    setSpeakerID(record.uid)

                    console.log('Mute Speaker',record.uid)
                    const remoteParticipant = VoxeetSDK.conference.participants.get(record.uid);
                    VoxeetSDK.audio.remote.stop(remoteParticipant);
                    //console.log(remoteParticipant)
            
                     })

          }
        }
            
          })
          .catch((error) => { 
            console.error('Error:', error);
          });

        },[])


      const unmuteSpeaker = (speaker_uid) => {
            
        const remoteParticipant = VoxeetSDK.conference.participants.get(speaker_uid);
       // console.log(remoteParticipant)
        VoxeetSDK.audio.remote.start(remoteParticipant);
        console.log(remoteParticipant)
      
       }

       const unmuteInterpreter = (interpreter_uid) => {
      //  console.log(interpreter_uid)
       // console.log(interpreterID)
        const remoteParticipant = VoxeetSDK.conference.participants.get(interpreter_uid);
        //console.log(remoteParticipant)
        VoxeetSDK.audio.remote.start(remoteParticipant);
        console.log(remoteParticipant) 
       }

      const selectSpeaker = async(val,id) => {
        console.log('value:', val)
        if(val === 'speaker'){
          setSpeakerID(id)
          setSpeakerPerson(true)
          setInterpreterPerson(false)
          getInterpreter(); // mute it
          unmuteSpeaker(id)
        }
        else if(val === 'interpreter'){
          setMessage('')
          setShouldFetch(false)
          //setinterpreterID(id)
          setSpeakerPerson(false)
          setInterpreterPerson(true)
          getOriginalSpeaker(); // mute it
          unmuteInterpreter(id)
          
        }
     }


     const checkstatus = (id) => {
    
        const remoteParticipant = VoxeetSDK.conference.participants.get(id);
        console.log(id)
        console.log(remoteParticipant) 

     }


      return (
        <div className="App" style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'center',
        backgroundColor: '#14141A',
      
        left:isMaxScreen?'2%':'32%'}}>


        {/* {CloseSessionModal &&
          <div className="custom-alert-overlay">
            <div className="custom-alert">
              <p>You are leaving the call in {counter}s</p>
              <button className='buttonAlert' onClick={onCloseSession}>Close</button>
            </div>
         </div>
         } */}

         
          {Essenitals({
                      participants,
                      isMaxScreen,
                      maximizedlayout,
                      maximizedlayout2,
                      btnMax,
                      btnMax2,
                      btnMax3,
                      btnMax4,
                      maxlayoutstyle,
                      minlayoutstyle,
                      isPresentationActive,
                      minmizedSharedScreen,
                      maximizedSharedScreen
                    })}



           {/* Min Share Screen Start */}
          { !isMaxScreen && isPresentationActive &&
          <div>
           <Space style={{BorderRadius:"11px",display: "block !important", position:"absolute", right:"0px", top:"207px", height: 'auto', flexGrow: 0,width:"17vw",background:"rgb(1 18 38 / 85%)",overflow:"hidden",overflowY:"hidden", maxHeight:"367px" }}> 
          <div style={{position:"relative",paddingTop:"37px"}}>
          <button  onClick={ScreenView} className='dolbyicon sidebtn'>
          <img  src="assets/Maximize.svg" alt="" /></button>
          </div>
            <ScreenSharingPresentationBox fallbackText={fallbackText} fallbackButtonText={fallbackButtonText} />
           <Space>
            <ParticipantsGrid  localText={localTextInGrid} additionalContainerStyle={{  }} />
          </Space>

          </Space>

          </div>

            }
           
          {isVideo2 && !isMaxScreen && !isPresentationActive &&
          <Space style={{borderRadius:"11px",display: "block !important", position:"absolute", right:"0px", top:"207px", height: 'auto', flexGrow: 0,width:"17vw",background:"rgb(1 18 38 / 85%)",overflow:"hidden",overflowY:"scroll", maxHeight:"405px" }}>
            <div style={{position:"relative",paddingTop:"12px"}}>
            <button  onClick={ScreenView} className='dolbyicon sidebtn'>
             <img  src="assets/Maximize.svg" alt="" /></button>
            </div>
              <ParticipantsGrid  localText={localTextInGrid} additionalContainerStyle={{  }} />
              {/* height:395 */}
          </Space>

          }

            {/* Min Share Screen End */}

            {/* Display Video Max Start */} 
    
           {isVideo2 && isMaxScreen && !isPresentationActive &&
          <Space style={{paddingLeft:"15px",position:"absolute", top:"6%",left:"2%",paddingRight:"15px",borderRadius:"11px", right:"0px", height: 'auto',width:"135vh", margin:'auto',background:"rgb(1 18 38 / 85%)",overflow:"hidden" }}>
            <div style={{position:'relative',height:"610px",padding:"15px"}}>
            <button  onClick={ScreenView} className='dolbyicon sidebar_max'>
             <img width='30px' src="assets/Minimize.svg" alt="" /></button>
            <ParticipantsGrid  localText={localTextInGrid} additionalContainerStyle={{ height: 680 }} />
            </div>
          </Space>
          
          }

           {/* Display Video Max Start */} 

            <Session participantInfo={{name:props.usern}}>
                    {!props.id ? (   
                    <div style={buttonContainerStyle}>  
                    </div>
                    ) : (
            
    <Conference id={props.id} key={props.id} >
               
           {isList &&
                
                <Space id="myElement" onClick={stopCtrl} fw style={{zIndex:'', position:"absolute",transform:"", top:"1px", left:"0px",right:"0px", overflowY: 'scroll',width:"50%",margin:"0 auto", borderRadius:"10px", overflow:"hidden", background:"rgb(1 18 38)"}}>
                 
                 
                  <div className="header_Participant">
                  <h3 className='ParticipantsText'>Participants ({participants.length})</h3>
                    <button className="close-button" onClick={CloseParticipantsList}></button>
                  </div>
              
               {participants.map((p,index) => {
              
                 return  <div style={{color: "#fff",
                  paddingLeft: "",paddingRight:"",display:"flex",justifyContent:"space-between"}} key={index}>
                    <div style={{textAlign:"left",width:"20%",paddingLeft:"20px"}}>{p.info.name} </div>
                    {/* <button style={{color:'#fff'}} onClick={() => checkstatus(p.id)}>check</button>  */}
                    
                 <div style={{textAlign:"right",width:"70%"}}>
                  {/* <button onClick={() => remoteAudio(participant.id)}>Action</button> */}

            {/* {!participantsStatus[p.id].isLocal && props.role === 'admin' && */}
            {!participantsStatus[p.id].isLocal  && props.role === 'admin' &&
                 <Dropdown style={{display:"inline-block"}} autoClose="outside">

                 <Dropdown.Toggle>
                 <svg fill="#ffffff"  width="21px"  id="Capa_1"  
                   viewBox="0 0 32.055 32.055" >
                   <path d="M3.968,12.061C1.775,12.061,0,13.835,0,16.027c0,2.192,1.773,3.967,3.968,3.967c2.189,0,3.966-1.772,3.966-3.967
                     C7.934,13.835,6.157,12.061,3.968,12.061z M16.233,12.061c-2.188,0-3.968,1.773-3.968,3.965c0,2.192,1.778,3.967,3.968,3.967
                     s3.97-1.772,3.97-3.967C20.201,13.835,18.423,12.061,16.233,12.061z M28.09,12.061c-2.192,0-3.969,1.774-3.969,3.967
                     c0,2.19,1.774,3.965,3.969,3.965c2.188,0,3.965-1.772,3.965-3.965S30.278,12.061,28.09,12.061z"/>
                 </svg>
               </Dropdown.Toggle> 
               <Dropdown.Menu size="md" title="">
               <Dropdown.Header style={{color:"#ffffff",fontSize:"14pt"}}>Change user role</Dropdown.Header>
                 <Dropdown.Divider />
                 <Dropdown.Item>

                 {spinnerLoad1 &&
                 <Spinner animation="border" size="sm" role="status" style={{ color: 'white' }}>
                 <span className="visually-hidden">Loading...</span>
                </Spinner>
                   }

                   <button className='dropdown-toggle btn btn-primary' onClick={() => markSpeaker(p.info.name,p.id,'speaker')}>{!webCamOpt[p.id]?'Mark as speaker':'Unmark as speaker'}</button>
                   
                   </Dropdown.Item>
                 
                 <Dropdown.Item> 
                 {spinnerLoad2 &&
                 <Spinner animation="border" size="sm" role="status" style={{ color: 'white' }}>
                 <span className="visually-hidden">Loading...</span>
                </Spinner>
                  }
                   <button className='dropdown-toggle btn btn-primary' onClick={() => markInterpreter(p.info.name,p.id,'interpreter')}>{!auidoOpt[p.id]?'Mark as interpreter':'Unmark as interpreter'}</button>
                   
                   </Dropdown.Item>
                 
                 {/* <Dropdown.Item><button className='dropdown-toggle btn btn-primary' onClick={() => shareScreenSwitcher(p.id)}>{!shareScreenOpt[p.id]?'Enable sharescreen':'Disable sharescreen'}</button></Dropdown.Item> */}
               </Dropdown.Menu>
           </Dropdown>
               }
              
                  {p.audioTransmitting === true &&
                   
                          <svg
                          style={{marginRight:"15px",width:"21px"}}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          data-testid={props.testID}
                          fill={props.fill || 'currentColor'}
                          >
                          <path
                            fill={props.fill || 'currentColor'}
                            d="M12 16.5a4.505 4.505 0 0 0 4.5-4.5V6a4.5 4.5 0 1 0-9 0v6a4.505 4.505 0 0 0 4.5 4.5ZM9 6a3 3 0 0 1 6 0v6a3 3 0 0 1-6 0V6Zm10.454 6.832a7.484 7.484 0 0 1-6.704 6.63v2.288a.75.75 0 1 1-1.5 0v-2.288a7.485 7.485 0 0 1-6.704-6.63.75.75 0 0 1 1.49-.164 6 6 0 0 0 11.927 0 .75.75 0 0 1 1.491.164Z"
                          />
                          </svg>
               }
                 {p.videoTransmitting === true &&
                      
                          <svg
                          style={{width:"21px"}}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          data-testid={props.testID}
                          fill={props.fill || 'currentColor'}
                          >
                          <path
                            fill={props.fill || 'currentColor'}
                            d="M22.877 6.851a.75.75 0 0 0-.75-.002L18 9.208v-.583a3.754 3.754 0 0 0-3.75-3.75h-12a1.502 1.502 0 0 0-1.5 1.5v9a3.754 3.754 0 0 0 3.75 3.75h12a1.502 1.502 0 0 0 1.5-1.5v-2.833l4.128 2.36a.75.75 0 0 0 1.122-.652v-9a.75.75 0 0 0-.373-.649ZM16.5 17.625h-12a2.253 2.253 0 0 1-2.25-2.25v-9h12a2.253 2.253 0 0 1 2.25 2.25v9Zm5.25-2.417L18 13.065v-2.13l3.75-2.143v6.416Z"
                          />
                          </svg>
                    
                   }

                  {p.audioTransmitting === false && 
                  <svg
                      style={{marginRight:"15px",width:"21px"}}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      data-testid={props.testID}
                      fill={props.fill || 'currentColor'}
                    >
                      <path
                        fill={props.fill || 'currentColor'}
                        d="m20.055 19.745-15-16.5a.75.75 0 1 0-1.11 1.01L7.5 8.165V12a4.5 4.5 0 0 0 6.93 3.787l1.019 1.122a5.999 5.999 0 0 1-9.412-4.241.75.75 0 0 0-1.491.164 7.485 7.485 0 0 0 6.704 6.63v2.288a.75.75 0 0 0 1.5 0v-2.288a7.473 7.473 0 0 0 3.714-1.436l2.481 2.729a.75.75 0 1 0 1.11-1.01ZM12 15a3.004 3.004 0 0 1-3-3V9.815l4.399 4.839A2.994 2.994 0 0 1 12 15ZM8.17 3.635A4.5 4.5 0 0 1 16.5 6v5.665a.75.75 0 1 1-1.5 0V6a3 3 0 0 0-5.553-1.576.75.75 0 1 1-1.276-.789Zm9.42 10.55a5.97 5.97 0 0 0 .373-1.517.75.75 0 0 1 1.491.164 7.467 7.467 0 0 1-.467 1.899.75.75 0 0 1-1.397-.547Z"
                      />
                  </svg>
               }
                {p.videoTransmitting === false &&
                  <svg
                    style={{width:"21px"}}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    data-testid={props.testID}
                    fill={props.fill || 'currentColor'}
                  >
                    <path
                      fill={props.fill || 'currentColor'}
                      d="M22.877 6.851a.75.75 0 0 0-.75-.002L18 9.208v-.583a3.754 3.754 0 0 0-3.75-3.75h-3.855a.75.75 0 0 0 0 1.5h3.855a2.253 2.253 0 0 1 2.25 2.25V13.5a.75.75 0 0 0 .023.183l.006.022c.016.057.04.113.069.165l.011.018c.012.02.025.039.038.057l.015.02a.798.798 0 0 0 .058.065c0 .002.002.003.003.004a.773.773 0 0 0 .144.11l.01.007 5.25 3a.75.75 0 0 0 1.123-.651v-9a.75.75 0 0 0-.373-.649Zm-1.127 8.357L18 13.065v-2.13l3.75-2.143v6.416ZM3.828 1.745a.75.75 0 1 0-1.11 1.01l1.927 2.12H2.25a1.502 1.502 0 0 0-1.5 1.5v9a3.754 3.754 0 0 0 3.75 3.75h12c.306 0 .605-.094.855-.27l3.09 3.4a.75.75 0 0 0 1.11-1.01L3.828 1.745Zm.672 15.88a2.253 2.253 0 0 1-2.25-2.25v-9h3.76l10.226 11.25H4.5Z"
                    />
                  </svg>
                }
              </div>
        
                  <hr 
                  style={{marginRight: "21px",
                    border:"1px solid #94949457"}}
                  />
                  
                  </div>
                 
                })
              } 

                </Space>
               }

              {/* {Speaker List} */}






              {isSpeakerOn && (
                <div ref={modalRef}>
                
                <Space fw style={{border:"1px solid #8ab4f8",  zIndex:'0', position:"absolute", bottom:"90px", left:"63px",right:"0px",height: 170,overflowY: 'scroll',width:"345px",margin:"0 auto", borderRadius:"10px", overflow:"hidden", background:"rgb(36 50 67)"}}  >
                      <header style={{fontSize:"17pt",fontWeight:"500",color:"white",padding:"5px",textAlign:"center"}}>Avaliable Speakers</header>
                      <ul id="caption" style={{listStyle:"none",color:"#fff",padding:"10px"}}>
                      {isLoading && recordSpeakers.length < 1 && <div>Loading ...</div> }
                      {!isLoading && recordSpeakers.length < 1 && <div>No Speakers Avaliable</div>}
                      {recordSpeakers.map((record, index) => ( 
                        <button  className='caption-button' onClick={ () => selectSpeaker(record.role,record.uid)}> <li style={{ textTransform: 'capitalize' }} key={index}>{record.pn.length > 10? record.pn.substring(0, 10)+' ...':record.pn}  ({record.role}) {speakerPerson === true && record.role === 'speaker' && <div style={{background:"green",width:"10px",height:"10px",borderRadius:"50px",display:"inline-block",marginLeft:"21%",marginBottom:"1px"}}></div>} {interpreterPerson === true && record.role !== 'speaker' && <div style={{background:"green",width:"10px",height:"10px",borderRadius:"50px",display:"inline-block",marginLeft:"21%",marginBottom:"1px"}}></div>} </li> </button>
                        // <button  className='caption-button'  onClick={{}}> <li>Interpreter - Arabic {isSelected === 'Ar' && <div style={{background:"green",width:"10px",height:"10px",borderRadius:"50px",display:"inline-block",marginLeft:"21%",marginBottom:"1px"}}></div>}</li> </button>
                        ))}
                       </ul>
                     
                     {/* {isLoading ? (
                          <div>Loading...</div> 
                        ) : (
                          <div style={{color:"#fff"}}>
                            
                            {recordSpeakers.map((record, index) => (
                              <div className='caption-button' key={index}>{record.role}</div> 
                           
                          </div>
                        )} */}
                
                </Space>
                </div>
                )}















              {isList && props.role === 'admin1Test' &&
                
                <Space  onClick={stopCtrl} fw style={{display:"none",zIndex:'999', position:"absolute", top:"55px", left:"0px",right:"0px",height: 615, overflowY: 'scroll',width:"50%",margin:"0 auto", borderRadius:"10px", overflow:"hidden", background:"rgb(1 18 38)"}}>
          
                  <div className="header_Participant">
                  <h3 className='ParticipantsText'>Participants ({participants.length})</h3>
                    <button className="close-button" onClick={CloseParticipantsList}></button>
                  </div>
                 
                
               
               {participants.map((p,index) => {
                 
                 return  <div style={{color: "#fff",
                 paddingLeft: "14px",paddingRight:"5px",display:"flex",justifyContent:"space-between"}}>
                    <div style={{textAlign:"left"}}>{p.info.name} </div>
                   
               
        <div style={{textAlign:"right",width:"70%"}}>

                  {p.audioTransmitting === true &&
                   
                  //  <svg
                  //  style={{marginRight:"15px",width:"21px"}}
                  //  viewBox="0 0 24 24"
                  //  xmlns="http://www.w3.org/2000/svg"
                  //  data-testid={props.testID}
                  //  fill={props.fill || 'currentColor'}
                  //  >
                  //  <path
                  //    fill={props.fill || 'currentColor'}
                  //    d="M12 16.5a4.505 4.505 0 0 0 4.5-4.5V6a4.5 4.5 0 1 0-9 0v6a4.505 4.505 0 0 0 4.5 4.5ZM9 6a3 3 0 0 1 6 0v6a3 3 0 0 1-6 0V6Zm10.454 6.832a7.484 7.484 0 0 1-6.704 6.63v2.288a.75.75 0 1 1-1.5 0v-2.288a7.485 7.485 0 0 1-6.704-6.63.75.75 0 0 1 1.49-.164 6 6 0 0 0 11.927 0 .75.75 0 0 1 1.491.164Z"
                  //  />
                  //  </svg>
                  <EnabledAudioIcon fill="#ffffff" testID="custom-svg-1" />
        }
          {p.videoTransmitting === true &&
               
                  //  <svg
                  //  style={{width:"21px"}}
                  //  viewBox="0 0 24 24"
                  //  xmlns="http://www.w3.org/2000/svg"
                  //  data-testid={props.testID}
                  //  fill={props.fill || 'currentColor'}
                  //  >
                  //  <path
                  //    fill={props.fill || 'currentColor'}
                  //    d="M22.877 6.851a.75.75 0 0 0-.75-.002L18 9.208v-.583a3.754 3.754 0 0 0-3.75-3.75h-12a1.502 1.502 0 0 0-1.5 1.5v9a3.754 3.754 0 0 0 3.75 3.75h12a1.502 1.502 0 0 0 1.5-1.5v-2.833l4.128 2.36a.75.75 0 0 0 1.122-.652v-9a.75.75 0 0 0-.373-.649ZM16.5 17.625h-12a2.253 2.253 0 0 1-2.25-2.25v-9h12a2.253 2.253 0 0 1 2.25 2.25v9Zm5.25-2.417L18 13.065v-2.13l3.75-2.143v6.416Z"
                  //  />
                  //  </svg>

                  <EnabledVideoIcon fill="#ffffff" testID="custom-svg-2" />
             
           
            }

           {p.audioTransmitting === false && 
          //  <svg
          //      style={{marginRight:"15px",width:"21px"}}
          //      viewBox="0 0 24 24"
          //      xmlns="http://www.w3.org/2000/svg"
          //      data-testid={props.testID}
          //      fill={props.fill || 'currentColor'}
          //    >
          //      <path
          //        fill={props.fill || 'currentColor'}
          //        d="m20.055 19.745-15-16.5a.75.75 0 1 0-1.11 1.01L7.5 8.165V12a4.5 4.5 0 0 0 6.93 3.787l1.019 1.122a5.999 5.999 0 0 1-9.412-4.241.75.75 0 0 0-1.491.164 7.485 7.485 0 0 0 6.704 6.63v2.288a.75.75 0 0 0 1.5 0v-2.288a7.473 7.473 0 0 0 3.714-1.436l2.481 2.729a.75.75 0 1 0 1.11-1.01ZM12 15a3.004 3.004 0 0 1-3-3V9.815l4.399 4.839A2.994 2.994 0 0 1 12 15ZM8.17 3.635A4.5 4.5 0 0 1 16.5 6v5.665a.75.75 0 1 1-1.5 0V6a3 3 0 0 0-5.553-1.576.75.75 0 1 1-1.276-.789Zm9.42 10.55a5.97 5.97 0 0 0 .373-1.517.75.75 0 0 1 1.491.164 7.467 7.467 0 0 1-.467 1.899.75.75 0 0 1-1.397-.547Z"
          //      />
          //  </svg>

          <DisabledAudioIcon fill="#ffffff" testID="custom-svg-3" />
        }
         {p.videoTransmitting === false &&
          //  <svg
          //    style={{width:"21px"}}
          //    viewBox="0 0 24 24"
          //    xmlns="http://www.w3.org/2000/svg"
          //    data-testid={props.testID}
          //    fill={props.fill || 'currentColor'}
          //  >
          //    <path
          //      fill={props.fill || 'currentColor'}
          //      d="M22.877 6.851a.75.75 0 0 0-.75-.002L18 9.208v-.583a3.754 3.754 0 0 0-3.75-3.75h-3.855a.75.75 0 0 0 0 1.5h3.855a2.253 2.253 0 0 1 2.25 2.25V13.5a.75.75 0 0 0 .023.183l.006.022c.016.057.04.113.069.165l.011.018c.012.02.025.039.038.057l.015.02a.798.798 0 0 0 .058.065c0 .002.002.003.003.004a.773.773 0 0 0 .144.11l.01.007 5.25 3a.75.75 0 0 0 1.123-.651v-9a.75.75 0 0 0-.373-.649Zm-1.127 8.357L18 13.065v-2.13l3.75-2.143v6.416ZM3.828 1.745a.75.75 0 1 0-1.11 1.01l1.927 2.12H2.25a1.502 1.502 0 0 0-1.5 1.5v9a3.754 3.754 0 0 0 3.75 3.75h12c.306 0 .605-.094.855-.27l3.09 3.4a.75.75 0 0 0 1.11-1.01L3.828 1.745Zm.672 15.88a2.253 2.253 0 0 1-2.25-2.25v-9h3.76l10.226 11.25H4.5Z"
          //    />
          //  </svg>

          <DisabledVideoIcon fill="#ffffff" testID="custom-svg-4" />
         }
        </div>

         <hr style={{marginRight: "21px",  border:"1px solid #94949457"}}/>   
     </div>
                 
                })
              } 

                </Space>
               }

                {/* Screen Sharing */}
                {isPresentationActive &&(
                  <ScreenSharingActionBar
                    statusLabels={{
                      active: screenSharingActionBarTexts.status.active,
                      error: screenSharingActionBarTexts.status.error,
                      loading: screenSharingActionBarTexts.status.loading,
                      other: screenSharingActionBarTexts.status.other,
                    }}
                    buttonLabels={{
                      tooltip: screenSharingActionBarTexts.button.tooltip,
                      label: screenSharingActionBarTexts.button.label,
                    }}
                    guestLabel={screenSharingActionBarTexts.guest}
                    // onClick={startScreenSharing}
                  />
                )}
                {/* Conference Recording */}
                {isRecordingActive && (
                  <RecordingActionBar
                    statusLabels={{
                      active: RecordingActionBarTexts.status.active,
                      error: RecordingActionBarTexts.status.error,
                      loading: RecordingActionBarTexts.status.loading,
                      other: RecordingActionBarTexts.status.other,
                    }}
                    buttonLabels={{
                      active: {
                        tooltip: RecordingActionBarTexts.buttonLabels.active.tooltip,
                        label: RecordingActionBarTexts.buttonLabels.active.label,
                      },
                      error: {
                        tooltip: RecordingActionBarTexts.buttonLabels.error.tooltip,
                        label: RecordingActionBarTexts.buttonLabels.error.label,
                      },
                    }}
                    guestLabel={RecordingActionBarTexts.guest}
                  />
                )}
               
              {isPresentationActive && isMaxScreen === true && (

              // 10% left - width 75%
              <Space  style={{position:"absolute",top:"12%",left:"7%",right:"7%",alignItems:"center",width:"174vh",height:"525px",display: 'flex' ,background:"rgb(1 18 38 / 80%)",margin:"0 auto",padding:"15px",borderRadius:"11px"}}>
                <div className='left-column'>
                  <div style={{position:"relative"}}>
                  {/* <h2>{status === 'active'? props.usern:''}</h2>  490px */}
                  <button  onClick={ScreenView} className='dolbyicon sidebtnshare'>
                       <img width="35px" src="assets/Minimize.svg" alt="" /></button>
                  <Space fw style={{ height: "100%",width:"", flexGrow: 1 }}>
                   
                    <ScreenSharingPresentationBox />
                  </Space>
                  <p style={{
                    position: "absolute",
                    background: "#011226",
                    color: "#fff",
                    bottom: "-15px",
                    left: "50%",
                    padding:"1px"
                  }}
                  
                  
                  >{isLocalUserPresentationOwner?participant.info.name:''}</p>
                  
                  </div>
                </div>


               <div className='right-column' >
                <div className='ParticipantsListShare right-subcolumn'>
                  <p><b>Participants</b></p>
                </div>
                <div style={{borderRadius:"11px"}}>
                <Space className='right-subcolumn' style={{borderRadius:"",marginLeft:"15px",display: "block !important", position:"", right:"0px", top:"207px", height: 'auto', flexGrow: 0,width:"38vh",background:"",overflowX:"clip",overflowY:"scroll", maxHeight:"387px" }}>
                   
                  <ParticipantsGrid  localText={localTextInGrid} additionalContainerStyle={{ }} />
                </Space> 
                </div>
                </div>
                </Space>
             

               )}

              {/* End of share component  */}

                {/* Media Control Buttons */}
                <div style={{ ...buttonContainerStyle, gap: '10px' }}>
                

          <div style={{backgroundColor:"#011226b3",zIndex:"9999999999999999999",textAlign:"center",borderRadius: "11px",margin: "0 auto",padding: "5px", position:"absolute", bottom:"15px", paddingRight: "12px" }} >

                  {/* Begining of ToolTips */}
             <ReactTooltip 
              anchorId="mic-unmic"
              place="top"
              content={!isAudio? 'Mute' : 'Unmute' }
             />
              <ReactTooltip
              anchorId="vid-unvid"
              place="top"
              content={isVideo2? 'Stop video' : 'Start video' }
             />

              <ReactTooltip
              anchorId="people"
              place="top"
              content={isList? 'Hide participants' : 'Show participants' }
             />

              <ReactTooltip
              anchorId="share-en"
              place="top"
              content={status === 'active' &&  isPresentationModeActive? 'Stop screen share' : 'Start screen share' }
             /> 

              {/* <ReactTooltip
               anchorId="captionTranslate"
                place="top"
                content='Caption/Subtitle'
              />  */}

              <ReactTooltip
                    id="lang-en"
                    place="top"
                    //content='language/Interpretation'
                   // style={{ backgroundColor: "rgb(255, 255, 255)", color: "#222" }}
                   /> 

             <ReactTooltip
              anchorId="end"
              place="top"
              content='End call'
             /> 

             {/* End of ToolTips */}
          
         
           <button id="mic-unmic"  className="dolbybtn" onClick={micUnmic} style={{width:""}}>
         
            {isAudio ? <img  src="assets/Mic-Disabled.svg" alt="" /> : <img src="assets/Mic-Enabled.svg" alt="" /> }
            
            </button>

            <button id="vid-unvid" className="dolbybtn" onClick={videoUnvideo} style={{width:""}}>
           

             {isVideo2 ? <img src="assets/Video-Enabled.svg" alt="" /> : <img src="assets/Video-Disabled.svg" alt="" /> } 
            
            </button>

            <button id="share-en" className="dolbybtn" onClick={startScreenSharing} style={{width:""}}>
            
            {status === 'active' && isPresentationModeActive? <img src="assets/Screenshare-Enabled.svg" alt="" /> : <img src="assets/Screenshare-Disabled.svg" alt="" /> }
           
           </button>

           <button data-tooltip-id="lang-en" data-tooltip-content="Language/Interpretation" className="dolbybtn paddingbtn" onClick={showSpeakers} >
                
              {isSpeakerOn ? <img src="assets/se_on.png" alt="" /> : <img src="assets/se.png" alt="" /> }
               
             </button>

           <button id="people" className="dolbybtn" onClick={showParticipants} style={{width:""}}>  
            {isList ? <img src="assets/people.svg" alt="" /> : <img src="assets/hide_people.svg" alt="" /> }
           </button>

            <button id="end" className="dolbybtn" onClick={endCall} style={{width:""}}>
                <img src="assets/Endcall.svg" alt="" />
            </button>
            </div>
          {/* End of NavBar */}

                </div>
               
              </Conference>
            )}
          </Session>

        </div>
      )
    }

    export default Content;