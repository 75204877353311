import React from 'react';

function Essenitals( 
    {
    participants,
    isMaxScreen,
    maximizedlayout,
    maximizedlayout2,
    btnMax,
    btnMax2,
    btnMax3,
    btnMax4,
    maxlayoutstyle,
    minlayoutstyle,
    isPresentationActive,
    minmizedSharedScreen,
    maximizedSharedScreen
   }
) {
  return (
    <style> 
    {/* {stylevideo} */}

    {participants.length === 1 && isMaxScreen?maximizedlayout:''}
    {participants.length > 1 && isMaxScreen?maximizedlayout2:''}
    {participants.length > 4 && isMaxScreen?btnMax:''}
    {participants.length < 5 && isMaxScreen?btnMax2:''}
    {participants.length === 2 && isMaxScreen?btnMax3:''}
    {participants.length > 2 && participants.length < 5 && isMaxScreen?btnMax4:''}


    {isMaxScreen?maxlayoutstyle:minlayoutstyle}
    {isPresentationActive?minlayoutstyle:''}
    {isPresentationActive &&!isMaxScreen?minmizedSharedScreen:''}
    {isPresentationActive&&isMaxScreen?maximizedSharedScreen:''}
   

  </style>
  );
}

export default Essenitals;