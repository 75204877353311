
export const QUERY_KEYS = {
  user: 'user',
  templates: 'templates',
  metaverses: 'metaverses',
  profile: 'profile',
  collaborators: 'collaborators',
  sharedMetaverses: 'sharedMetaverses',
  settings: 'settings',
  invitations: 'invitations',
}

export const DASHBOARD_SECTION_TYPES = {
  TEMPLATES: 'templates',
  SHARED: 'shared',
  MYWORLDS: 'myworlds',
}
//5.6.0 
//5.5.9

// const BuildVersion = '5.6.19'
// const unityVersion = '2022.3.20f1'
// export const UNITY_URLS = {
//   dataUrl: `https://cdn.holofair.net/build/${unityVersion}/${BuildVersion}/Build/WebGL.data.unityweb`,
//   frameworkUrl: `https://cdn.holofair.net/build/${unityVersion}/${BuildVersion}/Build/WebGL.framework.js.unityweb`,
//   loaderUrl: `https://cdn.holofair.net/build/${unityVersion}/${BuildVersion}/Build/WebGL.loader.js`,
//   codeUrl: `https://cdn.holofair.net/build/${unityVersion}/${BuildVersion}/Build/WebGL.wasm.unityweb`,
//   streamingAssetsUrl: `https://cdn.holofair.net/build/${unityVersion}/${BuildVersion}/StreamingAssets`,
  
// }

const BuildVersion = '5.6.28'
const unityVersion = '2022.3.27f1'
export const UNITY_URLS = {
  dataUrl: `https://cdn.holofair.io/build/${unityVersion}/${BuildVersion}/Build/WebGL.data.unityweb`,
  frameworkUrl: `https://cdn.holofair.io/build/${unityVersion}/${BuildVersion}/Build/WebGL.framework.js.unityweb`,
  loaderUrl: `https://cdn.holofair.io/build/${unityVersion}/${BuildVersion}/Build/WebGL.loader.js`,
  codeUrl: `https://cdn.holofair.io/build/${unityVersion}/${BuildVersion}/Build/WebGL.wasm.unityweb`,
  streamingAssetsUrl: `https://cdn.holofair.io/build/${unityVersion}/${BuildVersion}/StreamingAssets`,
  companyName: "holofairtechltd",
  productName: "holofair",
  productVersion: "5.6.28",
  platform: "desktop"

}

