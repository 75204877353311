

export const participantInfo = { name: 'Ayas Hus.' };

export const joinOptions = {
  constraints: {
    audio: false,
    video: false,
  },
};

// The name of the conference
export const meetingName = 'My Meeting';

// The name of current participant
export const username = 'John Doe';

// Define the tooltips that are shown when hovering over the Join or Leave buttons.
export const joinTooltipText = 'Join meeting';
export const leaveTooltipText = 'Leave';

// Define the `ParticipantsList` configuration: you can customise the text shown for each participant and their status.
export const localText = 'you'; // The text shown for the current participant's name.
export const muteText = 'mute'; // Displayed in a tooltip when a participant is not muted.
export const unmuteText = 'unmute'; // Displayed in a tooltip when a participant is muted.
export const soundOnText = 'sound on'; // Displayed in a tooltip when a participant is speaking.
export const soundOffText = 'sound off'; // Displayed in a tooltip when a participant is not speaking.

// Define the `ParticipantsGrid` configuration: you can customise the text shown for the current participant and their status.

// Define the `CameraSelect`, `MicrophoneSelect`, and `SpeakersSelect` configurations: each component takes a `label` prop (shown above the component) and a `placeholder` prop (shown when no option is selected).
export const cameraLabel = 'Camera';
export const cameraPlaceholder = 'Choose a camera';

export const microphoneLabel = 'Microphone';
export const microphonePlaceholder = 'Choose a microphone';

export const speakersLabel = 'Speaker';
export const speakersPlaceholder = 'Choose a speaker';

// Define the `ScreenSharingActionBar` configurations: component takes a statusLabels (shown depends of sharing status) and buttonLabels.
export const screenSharingActionBarTexts = {
  status: {
    active: 'Screen sharing active',
    error: 'Screen sharing issue',
    loading: 'Screen sharing loading',
    other: 'Screen sharing other status',
  },
  button: {
    label: 'Stop presenting',
    tooltip: 'Stop presenting',
  },
  guest: 'Someone is presenting',
};

// Define the `ScreenSharingPresentationBox` configurations: component takes a "fallbacktext" and "fallbackButtonText" props for default fallback content.
export const fallbackText = 'There is some problem with screen sharing';
export const fallbackButtonText = 'try again';

// Define the `RecordingActionBar` configurations: component takes a statusLabels (shown depends of recording status) and buttonLabels.
export const RecordingActionBarTexts = {
  status: {
    active: 'Recording active',
    error: 'Recording error',
    loading: 'Recording loading',
    other: 'Recording other status',
  },
  buttonLabels: {
    active: {
      tooltip: `Stop recording`,
      label: `Stop recording`,
    },
    error: {
      tooltip: `Try again`,
      label: `Try again`,
    },
  },
  guest: 'Someone is recording',
};



export const localTextInGrid = 'you';

// styles

export const maxlayoutstyle = `
.VideoGrid-module_content__16nAm{
display: flex !important
}

.VideoGrid-module_tileWrapper__Gy7MF{
padding: 0px !important;
}


.ParticipantsGridItem-module_rightIndicatorsSection__J77FI{
top: 85% !important;
}

.ParticipantsGridItem-module_pill__spcyU{
// bottom: 28px !important;
}

`;

export const minlayoutstyle = `
.VideoGrid-module_content__16nAm{
display: inline-table !important
}

.VideoGrid-module_tileWrapper__Gy7MF{
max-width: 100% !important;
width: 100% !important;
padding: 1px !important;
}

.Text-module_text__0e3t8.Text-module_type-captionSmallDemiBold__7HKfV{
font-size: 12px !important;
}
.VideoGrid-module_tileWrapper__Gy7MF{
height:170px !important;
}

.IconIndicator-module_indicator__adfFd.IconIndicator-module_size-m__-RM9n{
width: 21px !important;
height: 21px !important;
}

.Text-module_text__0e3t8.Text-module_type-captionSmallDemiBold__7HKfV {
 font-size: 15px !important;
}

`;


export const buttonContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export const maximizedSharedScreen = `
  .VideoGrid-module_tileWrapper__Gy7MF{
     padding-bottom: 2px !important;
  }

  .ParticipantsGridItem-module_rightIndicatorsSection__J77FI{
    top: 83% !important;
  }
  .PresentationBox-module_container__muYQW{
        //  height:490px !important;
        height:100% !important;
        margin-top:10px !important;
  }
  .PresentationBox-module_container__muYQW video{
    object-fit: contain !important;
  }

  .IconIndicator-module_indicator__adfFd.IconIndicator-module_size-m__-RM9n{
    width: 21px !important;
    height: 21px !important;
  }

  .Text-module_text__0e3t8.Text-module_type-captionSmallDemiBold__7HKfV {
    font-size: 15px !important;
  }

`;

export const minmizedSharedScreen = 
  `
  .PresentationBox-module_container__muYQW video{
    height: 151px !important;
    object-fit: fill !important;
    overflow: hidden !important;
  }

  .ParticipantsGrid-module_gridWrapper__hc1Jf{
    margin-top:-41px !important;
  }
  .PresentationBox-module_container__muYQW{
    padding-left: 0px !important;
    border-radius: 0px !important;
  }

  .ParticipantsGridItem-module_rightIndicatorsSection__J77FI{
    top: 77% !important;
  }

  // .ParticipantsGridItem-module_pill__spcyU{
  //   bottom: 15px !important;
  // }

  .ParticipantsGridItem-module_pill__spcyU{
    bottom: 27px !important;
  }

  `;
      
  export const btnMax = `
      .sidebar_max{
        position: absolute;
        left:94%;
         top:55px; 
        z-index: 999; }

      `;
  export  const btnMax2 = `
      .sidebar_max{
        position: absolute;
        left:93.5%;
         top:50px; 
        z-index: 999; }

      `;
  export const btnMax3 = `
      .sidebar_max{
         top:30px; 
         left:94.5%;
      }
         .ParticipantsGridItem-module_rightIndicatorsSection__J77FI{
          top: 88% !important;
        }`;
   export const btnMax4 = `
      .sidebar_max{
        top:55px; 
        left:92.5%;
     }
      `;
  export const maximizedlayout = `
      .ParticipantsGridItem-module_rightIndicatorsSection__J77FI{
        top: 93% !important;
      }
      .sidebar_max{
        position: absolute;
        left:95%;
         top:40px; 
        z-index: 999; 
    }
      `;  
   export  const maximizedlayout2 = `
      .ParticipantsGridItem-module_rightIndicatorsSection__J77FI{
        top: 85% !important;
      }`;  

     
